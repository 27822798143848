exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-cgu-tsx": () => import("./../../../src/pages/cgu.tsx" /* webpackChunkName: "component---src-pages-cgu-tsx" */),
  "component---src-pages-cgv-tsx": () => import("./../../../src/pages/cgv.tsx" /* webpackChunkName: "component---src-pages-cgv-tsx" */),
  "component---src-pages-clinics-tsx": () => import("./../../../src/pages/clinics.tsx" /* webpackChunkName: "component---src-pages-clinics-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-events-congress-tsx": () => import("./../../../src/pages/events/congress.tsx" /* webpackChunkName: "component---src-pages-events-congress-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-events-webinars-tsx": () => import("./../../../src/pages/events/webinars.tsx" /* webpackChunkName: "component---src-pages-events-webinars-tsx" */),
  "component---src-pages-events-workshops-tsx": () => import("./../../../src/pages/events/workshops.tsx" /* webpackChunkName: "component---src-pages-events-workshops-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legals-tsx": () => import("./../../../src/pages/legals.tsx" /* webpackChunkName: "component---src-pages-legals-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-workstation-envision-tsx": () => import("./../../../src/pages/workstation/envision.tsx" /* webpackChunkName: "component---src-pages-workstation-envision-tsx" */),
  "component---src-pages-workstation-tsx": () => import("./../../../src/pages/workstation.tsx" /* webpackChunkName: "component---src-pages-workstation-tsx" */),
  "component---src-templates-addon-tsx": () => import("./../../../src/templates/addon.tsx" /* webpackChunkName: "component---src-templates-addon-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-treatment-tsx": () => import("./../../../src/templates/treatment.tsx" /* webpackChunkName: "component---src-templates-treatment-tsx" */)
}

